import React from "react"

import { LocalizedLink as Link } from "gatsby-theme-i18n"
import "../styles/rooms.css"

import { Button } from "../components/Button/Button"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

export default function RoomThumb({ data }) {
  const { t } = useTranslation()

  const { naam, id, fotos, orde } = data
  const { beschrijving } = data.beschrijving

  console.log(naam + "  " + orde)

  return (
    <Link to={`/rooms/${id}`}>
      <article className="article-room">
        <div style={{ display: "grid" }}>
          <Img
            fluid={fotos[0].fluid}
            key={fotos[0].contentful_id}
            alt={fotos[0].description}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <h2 className="article-room-title h2-title">{naam}</h2>
        <p className="article-room-text">{beschrijving.substring(0, 200)}...</p>
        <Button path={`/rooms/${id}`} text={t("home.15")} />
      </article>
    </Link>
  )
}
