import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import RoomThumb from "../components/RoomThumb"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import SEO from "../components/SeoComponent"

import { useTranslation } from "react-i18next"

import { Carousel } from "react-responsive-carousel"

export default function Rooms({ location, data }) {
  const [path, setPath] = useState(location)

  const rooms = data?.allContentfulKamer?.edges

  let rooms_sorted = rooms.map(function (item) {
    return item.node
  })

  rooms_sorted.sort((a, b) => parseFloat(b.orde) - parseFloat(a.orde))

  const { t } = useTranslation()

  useEffect(() => {
    setPath(location.pathname.substring(1))
  }, [])

  if (typeof window !== "undefined") {
    document.body.style.overflow = "visible"
  }

  return (
    <Layout path={"rooms"}>
      <SEO title={t("Kamers")} />
      <div className="background-box"></div>
      <section className="rooms">
        <h1 className="rooms-title h2">{t("rooms.1")}</h1>
        <p
          className="rooms-text"
          dangerouslySetInnerHTML={{
            __html: t("rooms.2", {
              interpolation: { escapeValue: false },
            }),
          }}
        ></p>
        <div className="rooms-grid">
          {rooms_sorted.map(room => (
            <RoomThumb key={room.id} data={room} />
          ))}
        </div>
      </section>
      <div className="ratings">
        <div className="rating-container">
          <div className="ratings-item">
            <p className="ratings-item-big">8,8</p>
            <p className="ratings-item-small">{t("rooms.3")}</p>
          </div>
          <div className="ratings-item">
            <p className="ratings-item-big">8,8</p>
            <p className="ratings-item-small">{t("rooms.4")}</p>
          </div>
          <div className="ratings-item">
            <p className="ratings-item-big">9,5</p>
            <p className="ratings-item-small">{t("rooms.5")}</p>
          </div>
          <div className="ratings-item">
            <p className="ratings-item-big">+500</p>
            <p className="ratings-item-small">{t("rooms.6")}</p>
          </div>
        </div>
      </div>
      <section className="testimonials">
        <h1 className="testimonials-title h2">{t("rooms.7")}</h1>
        <div className="testimonials-container">
          <Carousel
            showStatus={false}
            showArrows={true}
            showThumbs={false}
            emulateTouch={true}
            interval={10000}
            infiniteLoop={true}
            stopOnHover={true}
            swipeScrollTolerance={100}
            autoPlay
          >
            <div>
              <p className="testimonials-quote">
                “Prachtige plek! We hebben genoten! Gastheer Marco is zeer
                voorkomend en gastvrij. Zijn aanbevelingen voor
                bezienswaardigheden opgevolgd en wat was het allemaal bijzonder
                en mooi. Ontbijt was heerlijk. Kamers zijn allen anders
                ingericht. Stuk voor stuk even sfeervol. Ook leuk dat we bij
                aankomst mochten kiezen welke kamer we wilden. Kortom een
                aanrader!”
              </p>
              <p className="testimonials-author">- Danielle</p>
            </div>
            <div>
              <p className="testimonials-quote">
                “Prachtige plek! We hebben genoten! Gastheer Marco is zeer
                voorkomend en gastvrij. Zijn aanbevelingen voor
                bezienswaardigheden opgevolgd en wat was het allemaal bijzonder
                en mooi. Ontbijt was heerlijk. Kamers zijn allen anders
                ingericht. Stuk voor stuk even sfeervol. Ook leuk dat we bij
                aankomst mochten kiezen welke kamer we wilden. Kortom een
                aanrader!”
              </p>
              <p className="testimonials-author">- Danielle</p>
            </div>
            <div>
              <p className="testimonials-quote">
                “Prachtige plek! We hebben genoten! Gastheer Marco is zeer
                voorkomend en gastvrij. Zijn aanbevelingen voor
                bezienswaardigheden opgevolgd en wat was het allemaal bijzonder
                en mooi. Ontbijt was heerlijk. Kamers zijn allen anders
                ingericht. Stuk voor stuk even sfeervol. Ook leuk dat we bij
                aankomst mochten kiezen welke kamer we wilden. Kortom een
                aanrader!”
              </p>
              <p className="testimonials-author">- Danielle</p>
            </div>
          </Carousel>
        </div>
      </section>
      <section className="breakfast">
        <div style={{ display: "grid" }} className="breakfast-container-left">
          <StaticImage
            src="../images/ontbijtBig.png"
            width={562}
            height={410}
            quality={95}
            placeholder="blurred"
            style={{ gridArea: "1/1" }}
            imgStyle={{ objectFit: "cover" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Ontbijt"
          />
        </div>
        <div className="breakfast-container-right">
          <h1 className="breakfast-title h2">{t("rooms.8")}</h1>
          <p className="breakfast-text">
            <span
              dangerouslySetInnerHTML={{
                __html: t("rooms.9", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></span>
            <br />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t("rooms.10", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></span>
          </p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.booking.com/hotel/be/fleur-de-lys.nl.html?aid=1288280;label=metagha-link-localuniversalBE-hotel-249724_dev-desktop_los-1_bw-0_dow-Thursday_defdate-1_room-0_lang-nl_curr-EUR_gstadt-2_rateid-0_aud-0_cid-_gacid-6642513561_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20210325;sid=0b08409047913fca6e995e4d5fc38af2;checkin=2021-03-25;checkout=2021-03-26;dest_id=-1973154;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1616691131;srpvid=7de4769d0734014a;type=total;ucfs=1&#no_availability_msg"
            className="button p-mulish button-rooms-booking"
          >
            {t("rooms.11")}
          </a>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulKamer {
      edges {
        node {
          badkamer
          beschrijving {
            beschrijving
          }
          prijsDoubleweekDag
          prijsDoubleweekend
          prijsSingleweekDag
          prijsSingleweekend
          naam
          fotos {
            description
            contentful_id
            resize {
              src
              height
              aspectRatio
              width
            }
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          id
          orde
        }
      }
    }
  }
`
